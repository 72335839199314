<template>
	<S2SForm title="Cash Centres">
		<S2SCard title="Add Cash Centre">
			<S2SFormGenerator ref="cashcentreForm" :schema="cashcentreSchema" :data="{}"></S2SFormGenerator>
			<v-flex class="text-xs-left text-md-right">
				<v-btn text :to="{ name: 'cash-centres' }" class="mr-2">Cancel</v-btn>
				<v-btn color="accent" @click="onAdd" :disabled="status.loading">Add</v-btn>
			</v-flex>
		</S2SCard>
	</S2SForm>
</template>

<script>
import Vue from "vue";
import cashcentreSchema from "@/static/config/forms/cashcentre-schema.json";
import { CashCentreBody } from "../../api/safes-api";

export default Vue.extend({
	name: "CashCentresCreate",

	data: function() {
		return {
			cashcentreSchema: cashcentreSchema
		};
	},

	computed: {
		status: function() {
			return this.$store.state.safes.status;
		}
	},

	methods: {
		async onAdd() {
			const form = this.$refs["cashcentreForm"];
			if (await form.validate()) {
				if (this.$store.dispatch("safes/createCashCentre", form.model)) {
					this.$router.push({ name: "cash-centres" });
				}
			}
		}
	}
});
</script>
